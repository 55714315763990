import { createSlice } from "@reduxjs/toolkit";
import initialState from "./state";
import reducers from "./reducers";

export const APP_REDUCER = createSlice({
  name: "app",
  initialState,
  reducers,
});

export const { SET_DRAWER_STATE, SET_APP_STATUS, SET_ACTIVE_INDEX, SET_ROUTES } = APP_REDUCER.actions;

export default APP_REDUCER.reducer;
