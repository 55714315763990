import ElRocketLogo from "../../assets/img/logo.svg";
import MenuDrawer from "../drawer/drawer";
import { connect } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { SET_ACTIVE_INDEX } from "../../redux/app/app.reducer";
import { useEffect } from "react";
import { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const NavBar = ({ routes, activeMenuIndex, setActiveMenuIndex }) => {
  const getActiveClases = (index) => {
    if (index === activeMenuIndex) {
      return "rounded-md bg-red";
    }

    return "";
  };

  const [bg, setBg] = useState("bg-transparent");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setBgHeader(window.pageYOffset);
    });
  });

  const setBgHeader = (offset) => {
    if (offset > 50) {
      setBg("bg-blue-dark");
    } else {
      setBg("bg-transparent");
    }
  };

  return (
    <nav className={`transition ease-in-out ${bg} duration-300 py-4 sticky top-0 z-10`}>
      <div className="sm:mx-auto md:mx-2 px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center justify-center">
            <div className="flex-shrink-0">
                <LazyLoadImage
                alt='El Rocket logo'
                src={ElRocketLogo}
                effect="opacity"
                style={{ width: "10rem", heigth: "10rem" }}
            />
              {/* <img style={{ width: "10rem", heigth: "10rem" }} src={ElRocketLogo} alt="Your Company" /> */}
            </div>
          </div>
          <div className="hidden md:block">
            <div className="flex items-center justify-center">
              <div className="hidden md:block">
                <div className="flex items-center justify-center md:space-x-2">
                  {routes.map(({ label, ref }, index) => (
                    <HashLink
                      to={ref}
                      key={index}
                      onClick={() => setActiveMenuIndex(index)}
                      scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                      className={`text-white px-3 py-2 rounded-md md:text-sm lg:text-md font-medium ${getActiveClases(
                        index,
                      )}`}
                    >
                      {label}
                    </HashLink>
                  ))}
                </div>
              </div>

              {/* <div className="relative ml-3">
                                <PrimaryButton svgIcon={ElRocketIcon} label="volemos juntos" responsive={true} />
                            </div> */}
            </div>
          </div>
          <div className="md:hidden xs:block">
            <MenuDrawer />
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = ({ app: { routes, activeMenuIndex } }) => {
  return { routes, activeMenuIndex };
};

const mapDispatchToProps = { setActiveMenuIndex: SET_ACTIVE_INDEX };

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(NavBar);

export default connectedComponent;
