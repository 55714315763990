import ElRocketIcon from "../../assets/img/icon-rocket.svg";
import { useEffect, useState, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { SET_DRAWER_STATE } from "../../redux/app/app.reducer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const GoTopButton = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPosition(window.pageYOffset);
    });
  });

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Transition
      as={Fragment}
      show={scrollPosition > 700}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0 rotate-[-120deg] scale-50"
      enterTo="opacity-100 rotate-0 scale-100"
      leave="transform duration-[400ms] transition ease-in-out"
      leaveFrom="opacity-100 rotate-0 scale-100"
      leaveTo="opacity-0 rotate-[-120deg] scale-50"
      className="sm:hidden"
    >
      {/* <div className="xs:h-24 xs:w-10 sm:h-28 sm:w-14 fixed z-10 sm:right-12 xs:right-4 sm:bottom-32 xs:bottom-20 shadow-md rounded-md flex flex-col justify-around items-center"> */}
      <div className="xs:h-10 xs:w-10 sm:h-28 sm:w-14 fixed z-10 sm:right-12 xs:right-4 sm:bottom-32 xs:bottom-20 shadow-md rounded-md flex flex-col justify-around items-center">
        <button
          className="bg-red w-full h-[100%] flex justify-center items-center rounded-md hover:bg-red-dark"
          onClick={scrollTop}
        >
          <LazyLoadImage
            alt='El Rocket logo'
            src={ElRocketIcon}
            effect="opacity"
            className="xs:w-full xs:h-4"
        />
          {/* <img className="xs:w-full xs:h-4" src={ElRocketIcon} alt="El Rocket logo" /> */}
        </button>

        {/* <button
                    onClick={() => setDrawer(!drawerIsOpen)}
                    className="bg-red h-[50%] w-full flex justify-center items-center rounded-md rounded-b-none mb-[.09rem] hover:bg-red-dark"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
                <button className="bg-red w-full h-[50%] flex justify-center items-center rounded-md rounded-t-none hover:bg-red-dark" onClick={scrollTop}>
                    <img className="xs:w-full xs:h-4" src={ElRocketIcon} alt="El Rocket logo" />
                </button> */}
      </div>
    </Transition>
  );
};

const mapStateToProps = ({ app: { drawerIsOpen } }) => ({ drawerIsOpen });

const mapDispatchToProps = { setDrawer: SET_DRAWER_STATE };

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(GoTopButton);

export default connectedComponent;

// export default GoTopButton
