import "./App.css";
import MainLayout from "./layouts/main-layout/main";
import Modal from "./components/modal/modal";

const App = () => {
  return (
    <>
      <Modal />
      <MainLayout />
    </>
  );
};

export default App;
