import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import { SET_DRAWER_STATE } from "../../redux/app/app.reducer";
import { HashLink } from "react-router-hash-link";
import { SET_ACTIVE_INDEX } from "../../redux/app/app.reducer";

const MenuDrawer = ({ drawerIsOpen, setDrawer, routes, activeMenuIndex, setActiveMenuIndex }) => {
  const getActiveClases = (index) => {
    if (index === activeMenuIndex) {
      return "rounded-md bg-red";
    }

    return "";
  };

  return (
    <>
      {!drawerIsOpen && (
        <button className="ring-1 ring-red rounded p-2 bg-red" onClick={() => setDrawer(!drawerIsOpen)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      )}
      <Transition.Root show={drawerIsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setDrawer}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden ">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-blue-dark py-6 shadow-xl">
                      <div className="px-4 flex justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Menú</Dialog.Title>
                        <button
                          type="button"
                          className="rounded-md text-gray-300 text-white focus:outline-none focus:ring-2 ring-white"
                          onClick={() => setDrawer(!drawerIsOpen)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <div className="h-full" aria-hidden="true">
                            <ul className="space-y-4 px-4">
                              {routes.map(({ label, ref }, index) => (
                                <li key={index} onClick={() => setDrawer(!drawerIsOpen)}>
                                  <HashLink
                                    to={ref}
                                    key={index}
                                    onClick={() => setActiveMenuIndex(index)}
                                    scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                                    className={`flex items-center p-2 text-xs font-light text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${getActiveClases(
                                      index,
                                    )}`}
                                  >
                                    {label}
                                  </HashLink>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapStateToProps = ({ app: { drawerIsOpen, routes, activeMenuIndex } }) => ({
  drawerIsOpen,
  routes,
  activeMenuIndex,
});

const mapDispatchToProps = { setDrawer: SET_DRAWER_STATE, setActiveMenuIndex: SET_ACTIVE_INDEX };

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MenuDrawer);

export default connectedComponent;
