import instance from "./config";

export const POST = ({ url, data, headers = {} }) => {
  return instance({
    url,
    method: "POST",
    data,
    headers,
  });
};

export const GET = ({ url, headers = {} }) => {
  return instance({
    url,
    method: "GET",
    headers,
  });
};
