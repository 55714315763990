/* eslint-disable react-hooks/exhaustive-deps */
import NavBar from "../../components/navbar/navbar";
import GoTopButton from "../../components/goTopButton/goTopButton";
import baseOperacionesImg from "../../assets/img/base-operaciones.svg";
// import Footer from "../../components/footer/footer";
// import Home from "./sections/home/home";
// import Misiones from "./sections/misiones/misiones";
// import Blog from "./sections/blog/blog";

// import CentroControl from "./sections/centro-de-control/centro-control";
// import ToolsComponent from "./sections/tools/tool";
import { GET } from "../../services/axios/http";
import { SET_ITEM, GET_ITEM, CLEAR } from "../../services/localStorage";
import { SET_APP_STATUS, SET_ROUTES } from "../../redux/app/app.reducer";
import { connect } from "react-redux";
import { useEffect, lazy } from "react";
import { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Misiones = lazy(() => import('./sections/misiones/misiones'));
const CentroControl = lazy(() => import('./sections/centro-de-control/centro-control'));
const Blog = lazy(() => import('./sections/blog/blog'));
const ToolsComponent = lazy(() => import('./sections/tools/tool'));
const Footer = lazy(() => import('../../components/footer/footer'));
const Home = lazy(() => import('./sections/home/home'));
const TeamMembersComponent = lazy(() => import('./sections/TeamMembers/TeamMembersComponent'));



const MainLayout = ({ setAppStatus, setRoutes }) => {
  const [titles, setTitles] = useState({ title1: "", title2: "", tb: "", bb: "" ,cc: "", tools: "", team:"", title1_alt: "", title1_description: "", title2_alt: "", title2_description: "", tb_alt: "", tb_description: "", bb_alt: "", bb_description: "", cc_alt: "", cc_description: "", tools_alt: "", tools_description: "", team_alt: "", team_description: "", title1_variant: "", title2_variant: "", tb_variant: "", bb_variant: "", cc_variant: "", tools_variant: "", team_variant: ""});

  const getSettings = async () => {
    try {
      const {
        data: { settings },
      } = await GET({
        url: "notion/getSettings",
      });
      let cache = settings[0].properties.Cache.checkbox;
      if (cache) {
        SET_ITEM({ key: "SETTINGS", value: settings });
      } else {
        CLEAR();
      }
    } catch ({ message }) {
      setAppStatus({ type: "Error", show: true, message });
    }
  };

  const buildMenu = async (menu) => {
    const m = [];
    await menu.forEach((item) => {
      const {
        properties: {
          tittle: { title },
          anchor: { rich_text },
        },
      } = item;
      const tittle = title[0].text.content;
      const anchor = rich_text[0].text.content;
      m.push({ label: tittle, ref: anchor });
    });

    return m;
  };

  const getMenu = async () => {
    const local_menu = GET_ITEM({ key: "MENU", type: "object" });
    if (!local_menu) {
      try {
        const {
          data: { menu },
        } = await GET({
          url: "notion/getMenu",
        });

        const m = await buildMenu(menu);
        SET_ITEM({ key: "MENU", value: m });
        setRoutes(m);
      } catch ({ message }) {
        setAppStatus({ type: "Error", show: true, message });
      }
    } else {
      setRoutes(local_menu);
    }
  };

  const setTitlesToshow = (structure) => {
    let title1 = "";
    let title1_alt = "";
    let title1_description = "";
    let title2 = "";
    let title2_alt = "";
    let title2_description = "";
    let tb = "";
    let bb = "";
    let cc = "";
    let tools = "";
    let team = "";
    let tb_alt = "";
    let tb_description = "";
    let bb_alt = "";
    let bb_description = "";
    let cc_alt = "";
    let cc_description = "";
    let tools_alt = "";
    let tools_description = "";
    let team_alt = "";
    let team_description = "";
    let title1_variant = "";
    let title2_variant = "";
    let tb_variant = "";
    let bb_variant = "";
    let cc_variant = "";
    let tools_variant = "";
    let team_variant = "";

    structure.forEach((element) => {

      if (element.properties.Index.number === 1) {
        console.log("🚀 ~ structure.forEach ~ element:", element.properties)

        title1 = element.properties.Description.rich_text[0].plain_text;
        title1_alt = element.properties.Alt.rich_text[0].plain_text;
        title1_description = element.properties.Description_Html.rich_text[0].plain_text;
        title1_variant = element.properties.Type.select.name;

      }

      if (element.properties.Index.number === 2) {
        title2 = element.properties.Description.rich_text[0].plain_text;
        title2_alt = element.properties.Alt.rich_text[0].plain_text;
        title2_description = element.properties.Description_Html.rich_text[0].plain_text;
        title2_variant = element.properties.Type.select.name;
      }
      if (element.properties.Index.number === 3) {
        cc = element.properties.Description.rich_text[0].plain_text;
        cc_alt = element.properties.Alt.rich_text[0].plain_text;
        cc_description = element.properties.Description_Html.rich_text[0].plain_text;
        cc_variant = element.properties.Type.select.name;
      }
      if (element.properties.Index.number === 4) {
        tools = element.properties.Description.rich_text[0].plain_text;
        tools_alt = element.properties.Alt.rich_text[0].plain_text;
        tools_description = element.properties.Description_Html.rich_text[0].plain_text;
        tools_variant = element.properties.Type.select.name;
      }
      if (element.properties.Index.number === 5) {
        team = element.properties.Description.rich_text[0].plain_text;
        team_alt = element.properties.Alt.rich_text[0].plain_text;
        team_description = element.properties.Description_Html.rich_text[0].plain_text;
        team_variant = element.properties.Type.select.name;
      }

      if (element.properties.Index.number === 6) {
        tb = element.properties.Description.rich_text[0].plain_text;
        tb_alt = element.properties.Alt.rich_text[0].plain_text;
        tb_description = element.properties.Description_Html.rich_text[0].plain_text;
        tb_variant = element.properties.Type.select.name;
      }

      if (element.properties.Index.number === 7) {
        bb = element.properties.Description.rich_text[0].plain_text;
        bb_alt = element.properties.Alt.rich_text[0].plain_text;
        bb_description = element.properties.Description_Html.rich_text[0].plain_text;
        bb_variant = element.properties.Type.select.name;
      }
    });

    setTitles({ title1, title2, tb, bb, cc, tools, team, title1_alt, title1_description, title2_alt, title2_description, tb_alt, tb_description, bb_alt, bb_description, cc_alt, cc_description, tools_alt, tools_description, team_alt, team_description, title1_variant, title2_variant, tb_variant, bb_variant, cc_variant, tools_variant, team_variant });
  };

  const setStructureToLocalStorage = (structure) => {
    SET_ITEM({ key: "STRUCTURE", value: structure });

    setTitlesToshow(structure);
  };

  const getStructure = async () => {
    const local_structure = GET_ITEM({ key: "STRUCTURE", type: "object" });
    if (!local_structure) {
      try {
        const {
          data: { structure },
        } = await GET({
          url: "notion/getStructure",
        });

        setStructureToLocalStorage(structure);
      } catch ({ message }) {
        setAppStatus({ show: true, type: "Error", message });
      }
    } else {
      setTitlesToshow(local_structure);
    }
  };

  useEffect(() => {
    getStructure();
    getSettings();
    getMenu();
  }, []);

  return (
    <div>
      <GoTopButton />
      <NavBar />
      {/* <div className="p-12"> */}
      <Home title={titles.title1} body={titles.title2} title1_alt={titles.title1_alt} title1_description={titles.title1_description} title2_alt={titles.title2_alt} title2_description={titles.title2_description} title1_variant={titles.title1_variant} title2_variant={titles.title2_variant} />
      <Misiones />
      <CentroControl title={titles.cc} cc_alt={titles.cc_alt} cc_description={titles.cc_description} cc_variant={titles.cc_variant}/>
      {/* </div> */}
      <Blog />
      <ToolsComponent title={titles.tools} tools_alt={titles.tools_alt} tools_description={titles.tools_description}  tools_variant={titles.tools_variant} />
      < TeamMembersComponent title={titles.team} team_alt={titles.team_alt} team_description={titles.team_description} teams_variant={titles.teams_variant}  />
      <LazyLoadImage
            alt='base-operaciones'
            src={baseOperacionesImg}
            effect="opacity"
            className="bottom-0 w-full"
        />
      {/* <img src={baseOperacionesImg} alt="base-operaciones" className="bottom-0 w-full" /> */}
      <Footer bannerTitles={{ title: titles.tb, body: titles.bb, bb_variant:titles.bb_variant }} />
    </div>
  );
};

const mapDispatchToProps = { setAppStatus: SET_APP_STATUS, setRoutes: SET_ROUTES };

const connectedComponent = connect(null, mapDispatchToProps)(MainLayout);

export default connectedComponent;
