const INITIAL_STATE = {
  drawerIsOpen: false,
  activeMenuIndex: 0,
  routes: [],
  status: {
    message: "",
    type: "",
    show: false,
  },
};

export default INITIAL_STATE;
