import axios from "axios";

const API_URL =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

const instance = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 409) {
      return Promise.reject(
        {
          message:
            "El contacto ya ha sido registrado anteriormente, por favor utilice otra dirección de correo electrónico",
        } || error.response.data.message,
      );
    }
    return Promise.reject(error);
  },
);

export default instance;
