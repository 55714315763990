const PREFIX = "@ELROCKET_";

export const SET_ITEM = ({ key, value, expirationDays = 30 }) => {
  const data = typeof value === "string" ? value : JSON.stringify(value);
  const expirationMilliseconds = expirationDays * 24 * 60 * 60 * 1000;
  const expirationTime = new Date().getTime() + expirationMilliseconds;

  const itemWithExpiration = {
    data,
    expirationTime,
  };

  localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(itemWithExpiration));
};

export const GET_ITEM = ({ key, type = "string" }) => {
  const retrieve_value = localStorage.getItem(`${PREFIX}${key}`);
  const value = type === "object" ? JSON.parse(retrieve_value) : retrieve_value;
  if (!value) return null;
  return JSON.parse(value.data);
};

export const REMOVE_ITEM = (key) => {
  localStorage.removeItem(`${PREFIX}${key}`);
};

export const CLEAR = () => {
  localStorage.clear();
};
