const REDUCERS = {
  SET_DRAWER_STATE: (state) => {
    state.drawerIsOpen = !state.drawerIsOpen;
  },
  SET_APP_STATUS: (state, { payload: status }) => {
    state.status = status;
  },
  SET_ACTIVE_INDEX: (state, { payload: index }) => {
    state.activeMenuIndex = index;
  },
  SET_ROUTES: (state, { payload: routes }) => {
    state.routes = routes;
  },
};

export default REDUCERS;
